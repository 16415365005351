import React, {useState, useEffect} from "react"
import "./style.css";
import { Link } from "gatsby"

export default function NewShop() {

  // ========== HomePage JSX ============

  return (
    <div id="newshop" className="newshop">

      <div class="background" />

      <div id="storefront">
        <div class="hero" />
        <div class="extender" />
        <div id="dialog" class="animate__animated animate__fadeIn animate__delay-1s animate__slow" />
      </div>
      <div class="cards">
        <h1 class="sectionHeader goldText">
          Explore KikoMints
        </h1>
        <Link to="/mystics" class="card mystics hoverGlow">
          <div class="button">
            <div class="inset blue">
              <span>See Mystics</span>
            </div>
          </div>
        </Link>
        <Link to="/bakery" class="card bakery hoverGlow">
          <div class="button">
            <div class="inset blue">
              <span>The Bakery</span>
            </div>
          </div>
        </Link>
        <Link class="card vip disabled">
          <a class="button disabled">
            <div class="inset blue">
              <span>Join VIP</span>
            </div>
          </a>
        </Link>
        <Link class="card mints disabled">
          <a class="button disabled">
            <div class="inset blue">
              <span>Kiko Mints</span>
            </div>
          </a>
        </Link>
        <Link class="card beatz disabled">
          <a class="button disabled">
            <div class="inset blue">
              <span>Get Beatz</span>
            </div>
          </a>
        </Link>
      </div>

      <div class="faq">
        <h1>FAQ</h1>
        <p>We’re rewriting the rules of web3 creation. Don’t have a blue chip budget? That won’t matter here, because at the shop, we’re building for every type of collector in the metaverse.</p>
        <h2>Where are the updates?</h2>
        <p>Follow our <a class="goldText" href="https://www.twitter.com/kikomints" target="_blank" rel="noopener noreferrer">Twitter</a> and <a class="goldText" href="https://discord.gg/kikomints" rel="noopener noreferrer">Discord</a> announcements!</p>
        <h2>What's the latest?</h2>
        <p>
          Kiko launched her Bear Market Bakery. Free mint, gone in 4 minutes. She then airdropped 20k more NFTs to collectors.<br /><br />

          30k baked goods currently exist. 3k have Mystic Essence, which she hopes will turn into companions. What about the other 27k baked goods?<br /><br />

          Who knows?
      </p>
      <h2>Who's behind KikoMints?</h2>
      <p>We’re creators from all over the world. We follow the web3 closely and adapt to the current meta. The founder has been doxxed to only a select few (Zeneca, Cool Cats founders, etc). Team members come from a variety of backgrounds with an even split between males and females. Though Kiko’s voice and look was crafted by the team using majority female input, her voice is brought to life by the male founder on socials.</p>
      <h2>What's the roadmap</h2>
      <p>Roads? Where we're going, we don't need… roads.</p>
      <p>You can count on the fact that we have a good number of things in the pipeline, but we're flexible and we'll vibe to the movements of the metaverse!</p>
      </div>
    </div>
  );
}
